<template>
<h1>Oops. Not found.</h1>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>

<style scoped>

</style>
